import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "./assets/css/app.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// import 'bootstrap-icons/font/bootstrap-icons';
// import VueNumberInput from 'vue-number-smarty';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import VueNumericInput from "vue-numeric-input";
import "./assets/css/button.css";
import "./assets/css/classname.css";
import "./assets/css/navbar.css";
import "./assets/css/compare.css";

// import "./assets/css/admin.css";

import Notifications from "@kyvg/vue3-notification";
createApp(App)
  .use(router)
  .use(store)
  // .use(VueNumberInput)
  .use(VueNumericInput)
  .use(Notifications)
  .use(vSelect)
  .mount("#app");
