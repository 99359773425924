let data = [
  {
    name: "vendor_Sign_In",
    path: "/signin/vendor",
    component: () => import("@/pages/Praveen/Auth/Vendor/SignIn.vue"),
    meta: {
      layout: "auth",
      title: "Sign In",
    },
  },
  {
    name: "vendor_Sign_UP",
    path: "/signup/vendor",
    component: () => import("@/pages/Praveen/Auth/Vendor/SignUp.vue"),
    meta: {
      layout: "auth",
      title: "Sign In",
    },
  },
  {
    name: "Verification",
    path: "/verifyemail",
    component: () => import("@/pages/Praveen/Auth/VerifyEmail.vue"),
    meta: {
      layout: "auth",
      title: "Verification",
    },
  },
  {
    name: "Forgot_Password",
    path: "/forgotpassword",
    component: () => import("@/pages/Praveen/Auth/ForgotPassword.vue"),
    meta: {
      layout: "auth",
      title: "Forgot Password",
    },
  },

  {
    name: "Reset_Password",
    path: "/resetpassword",
    component: () => import("@/pages/Praveen/Auth/ResetPassword.vue"),
    meta: {
      layout: "auth",
      title: "Reset Password",
    },
  },

  //finished
  {
    name: "buyer_Sign_In",
    path: "/signin/buyer",
    component: () => import("@/pages/Praveen/Auth/Buyer/SignIn.vue"),
    meta: {
      layout: "auth",
      title: "Sign In",
    },
  },
  {
    name: "buyer_Sign_Up",
    path: "/signup/buyer",
    component: () => import("@/pages/Praveen/Auth/Buyer/SignUp.vue"),
    meta: {
      layout: "auth",
      title: "Sign Up",
    },
  },

  //finished
  {
    name: "buyer_Registration",
    path: "/buyer/profile/edit",
    component: () =>
      import("@/pages/Praveen/Auth/Buyer/Registration/Register.vue"),
    meta: {
      layout: "home",
      title: "Registration",
      middleware: ["buyer"],
    },
  },
  //finished
  {
    name: "BuyerRegisterPreview",
    path: "/buyer/profile",
    component: () =>
      import("@/pages/Praveen/Auth/Buyer/Registration/RegisterPreview.vue"),
    meta: {
      layout: "home",
      title: "registerpreview",
      middleware: ["buyer"],
    },
  },

  {
    name: "vendor_Registration",
    path: "/vendor/profile/edit",
    component: () =>
      import("@/pages/Praveen/Auth/Vendor/Registration/Register.vue"),
    meta: {
      layout: "home",
      title: "Registration",
      middleware: ["vendor"],
    },
  },
  {
    name: "VendorRegisterPreview",
    path: "/vendor/profile",
    component: () =>
      import("@/pages/Praveen/Auth/Vendor/Registration/RegisterPreview.vue"),
    meta: {
      layout: "home",
      title: "registerpreview",
      middleware: ["vendor"],
    },
  },
];
export default data;
