import { createWebHistory, createRouter } from "vue-router";
import auth from "./routes/Praveen/auth.js";
import admin from "./routes/Praveen/admin.js";
import buyer from "./routes/Praveen/buyer.js";
import vendor from "./routes/Praveen/vendor.js";
import comp from "./routes/Praveen/comp.js";

// lazy-loaded
const routes = [
  // tender creation
  // {
  //   path: "/invite",
  //   component: () =>
  //     import(
  //       "./pages/Praveen/vendor newdesign/invite.vue"
  //     ),
  //   meta: {
  //     layout: "home",
  //   },
  // },
  {
    path: "/bids",
    component: () => import("./pages/Praveen/newdesign/bids.vue"),
    meta: {
      layout: "home",
      fortype: "buyer",
    },
  },
  {
    path: "/bidstemo",
    name: "vendor-tender-vlist",
    component: () => import("@/pages/Praveen/newdesign/worklist.vue"),
    meta: {
      layout: "home",
      fortype: "vendor",
    },
  },

  // end
  ...auth,
  ...buyer,
  ...vendor,
  ...admin,
  ...comp,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/verifyemail",
    "/signin/buyer",
    "/signup/buyer",
    "/signin/vendor",
    "/signup/vendor",
    "/resetpassword",
    "/forgotpassword",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = JSON.parse(localStorage.getItem("user"));
  if (authRequired && !loggedIn) {
    next("/signin/buyer");
  } else if (to.meta.fortype && !to.meta.fortype.includes(loggedIn.type)) {
    next("/dashboard");
  }
  next();
});

export default router;
