<template>
  <div class="m-0">
    <nav id="topNav" class="navbar navbar-expand-lg mx-3 m-2 top-nav sticky-top px-4">
      <div class="container-fluid">
        <a class="navbar-brand" href="#"
          ><img src="@/assets/images/eTender.png" alt="" class="" />
        </a>
        <div class="d-flex align-items-center justify-content-center d-lg-none">
          <ul class="navbar-nav flex-row align-items-center justify-content-center icons">
            <li class="nav-item">
              <!-- search onclick -->
              <a href="" class="nav-link"
                ><i
                  class="fa-solid fa-fw fa-magnifying-glass text-white"
                  style="font-size: 20px"
                ></i
              ></a>
            </li>
            <li class="nav-item">
              <a type="button" class="nav-link text-white position-relative">
                <i
                  class="fa-solid text-white fa-bell fa-fw ms-2"
                  style="font-size: 18px"
                ></i>
                <span
                  class="position-absolute translate-middle badge rounded-pill bg-issues"
                  style="top: 7px; right: -0.4rem; padding: 3px 5px; font-size: 9px"
                >
                  2
                  <span class="visually-hidden">unread messages</span>
                </span>
              </a>
            </li>

            <li class="nav-item">
              <div class="dropstart">
                <a
                  type="button p-0"
                  class="btn-sm nav-link d-flex align-items-center position-relative"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="@/assets/images/profile.png" alt="" class="ms-2 w-100" />
                  <i class="fa-solid fa-caret-down text-white ms-2"></i>
                </a>
                <ul class="dropdown-menu mt-5 position-absolute top-0 end-0">
                  <li>
                    <router-link class="dropdown-item text-dark" to="/profile"
                      >Profile</router-link
                    >
                    <a class="dropdown-item text-dark" v-on:click="logOut">Log out</a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <button
            class="btn"
            type="button "
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fa-solid fa-bars text-white fs-4"></i>
          </button>
        </div>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-md-4 ms-0">
            <li class="nav-item">
              <router-link
                class="nav-link"
                :class="[checkpath('/dashboard') ? 'active' : '']"
                to="/dashboard"
                >Dashboard</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :class="[checkpath('/' + user.type + '/tender') ? 'active' : '']"
                :to="'/' + user.type + '/tender'"
                >Tenders</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :class="[checkpath('/' + user.type + '/auctions') ? 'active' : '']"
                :to="'/' + user.type + '/auctions'"
                >Auctions
              </router-link>
            </li>
            <li class="nav-item" v-if="user.type == 'buyer'">
              <router-link
                class="nav-link"
                :class="[checkpath('/' + user.type + '/vendor') ? 'active' : '']"
                :to="'/' + user.type + '/vendor'"
                >Vendors
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :class="[
                  $route.path.includes('/' + user.type + '/resourcelibrary')
                    ? 'active'
                    : '',
                ]"
                :to="'/' + user.type + '/resourcelibrary'"
                >Resource Library
              </router-link>
            </li>
          </ul>
        </div>
        <ul class="navbar-nav align-items-center d-none d-lg-flex align-items-center">
          <li class="nav-item text-white mx-2">
            {{ user.type.toUpperCase() }}
          </li>
          <li class="nav-item">
            <a href="" class="nav-link"
              ><i
                class="fa-solid fa-fw fa-magnifying-glass text-white"
                style="font-size: 16px"
              ></i
            ></a>
          </li>
          <li class="nav-item">
            <a type="button" class="nav-link text-white position-relative">
              <i class="fa-solid text-white fa-bell fa-fw" style="font-size: 18px"></i>
              <span
                class="position-absolute translate-middle badge rounded-pill bg-issues"
                style="top: 7px; right: -0.4rem; padding: 3px 5px; font-size: 9px"
              >
                2
                <span class="visually-hidden">unread messages</span>
              </span>
            </a>
          </li>

          <li class="nav-item">
            <div class="dropstart">
              <a
                type="button p-0"
                class="btn-sm nav-link d-flex align-items-center position-relative"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src="@/assets/images/profile.png" alt="" class="w-100" />
                <i class="fa-solid fa-caret-down text-white ms-2"></i>
              </a>
              <ul class="dropdown-menu mt-5 bg-dark position-absolute top-0 end-0">
                <li>
                  <router-link
                    class="dropdown-item text-dark"
                    :to="'/' + user.type + '/profile'"
                    >Profile</router-link
                  >
                </li>
                <li>
                  <button class="dropdown-item text-dark" @click="logOut">Log Out</button>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <main class="" style="background: #f0f4f7">
      <slot />
    </main>
  </div>
</template>

<script>
export default {
  props: ["user"],
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    checkpath(path) {
      if (this.$route.path.includes(path)) {
        return 1;
      }
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/signin/buyer");
    },
  },
};
</script>
